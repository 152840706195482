<script setup>
import { ref, onMounted, onBeforeMount, nextTick } from "vue";
import { GoogleMap, Marker, InfoWindow } from 'vue3-google-map';
import { getAllStudios, getNearByStudios, getLocationsByZipCode } from "../../mixins/api";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import Text from '../elements/Text.vue';
import Button from '../elements/Button.vue';
import SectionContainer from '../containers/SectionContainer.vue';
import ElementContaier from '../containers/ElementContaier.vue';
import SearchIcon from "../../assets/svg/SearchIcon.vue";
import { useRoute, useRouter } from "vue-router";
import { triggerTrackEvents } from "../../mixins/events_handler";
import initGoogleMaps from "../../utils/gmaps"; // Import the gmaps.js utility

const franchiseUrl = process.env.VUE_APP_FRANCHISE_URL;
const GOOGLE_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
const route = useRoute();
const queryParams = route.query;
const router = useRouter();

const searchQuery = ref("");
const autocompleteResults = ref([]);
const hasError = ref(false); // Track if there's an error
const locations = ref({});
const studios = ref([]);
const mapLocation = ref({ lat: 37.0902, lng: -95.7129 });
const zoom = ref(4);
const isMobile = ref(false);
const searchError = ref(""); // Add a ref to hold the error message
searchError.value = "State, City, Postal or Zip Code is required"; // Set the error message if the input is empty

const checkIsMobile = () => {
    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    isMobile.value = width < 640;
};
/*
const initAutocomplete = () => {
    const input = document.getElementById('autocomplete');
    const autocomplete = new google.maps.places.Autocomplete(input, {
        types: ['geocode'],
        componentRestrictions: { country: 'us' }
    });

    autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry) selectPlace(place);
        autocompleteResults.value = {
            description: place.formatted_address,
            place_id: place.place_id
        };
    });
};*/



const selectPlace = (place) => {
    const { lat, lng } = place.geometry.location;
    mapLocation.value = { lat: lat(), lng: lng() };
    zoom.value = 9;
    searchQuery.value = place.formatted_address;
    getLocationsByZipCode(searchQuery.value, studioResults);
    autocompleteResults.value = [];
    hasError.value = false; // Clear error when a place is selected
};

const getLatLang = () => {
    return new Promise((resolve) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                locations.value = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                resolve();
            }, () => {
                console.log('Location cannot be found');
                resolve();
            });
        } else {
            resolve();
        }
    });
};

const getStudios = () => {
    return getNearByStudios(locations.value.lat, locations.value.lng);
};

const selectStudioButton = (studioSlug) => {
    router.push({ path: '/' + studioSlug, query: queryParams })
        .then(() => { router.go(0) });
    triggerTrackEvents('Find-Location', 'Select Studio', 'find_go_to')
}

const onSubmit = () => {
    if (!searchQuery.value) {
        hasError.value = true; // Set error if the search input is empty
        return;
    }

    hasError.value = false; // Clear error if input is valid
    triggerTrackEvents('Find-Location', 'Search', 'find_search');
    getLocationsByZipCode(searchQuery.value, studioResults);
};


const studioResults = (response, lat, lng) => {
    mapLocation.value = { lat, lng };
    zoom.value = 9;
    studios.value = response;
};

const findLocationText = "We are always in the process of opening new locations." +
    "<a href='/contact'>Get on our email list</a>" +
    " to stay up to date or " +
    "<a href='" + franchiseUrl + "' target='_blank' class='nav-link'>learn how to open your own ILKB franchise</a>";

onBeforeMount(() => {
    window.addEventListener("resize", checkIsMobile);
});

onMounted(() => {
    checkIsMobile();

    initGoogleMaps().then(() => {
        initAutocomplete(); // Initialize Google Autocomplete

        // Ensure DOM is fully rendered before map initialization
        nextTick(() => {
            const mapElement = document.getElementById('map');
            if (mapElement) {
                getLatLang().then(() => {
                    const map = new google.maps.Map(mapElement, {
                        center: mapLocation.value,
                        zoom: zoom.value
                    });

                    if (locations.value.lat && locations.value.lng) {
                        getStudios().then((response) => {
                            mapLocation.value = {
                                lat: locations.value.lat,
                                lng: locations.value.lng
                            };
                            zoom.value = 9;
                            studios.value = response;

                            studios.value.forEach((studio) => {
                                const marker = new google.maps.Marker({
                                    position: {
                                        lat: parseFloat(studio.lat),
                                        lng: parseFloat(studio.lng),
                                    },
                                    map: map,
                                    title: studio.City + ', ' + studio.state.Code,
                                });

                                const infoWindow = new google.maps.InfoWindow({
                                    content: `<div class="find-map-info-window">
                                    <h5>${studio.City}, ${studio.state.Code}</h5>
                                    <p>${studio.Line1}</p>
                                    <p>${studio.City}, ${studio.state.Code} ${studio.ZipCode}</p>
                                    <p>${studio.phone}</p>
                                </div>`,
                                });

                                marker.addListener('click', () => {
                                    infoWindow.open(map, marker);
                                });
                            });
                        }).catch((err) => {
                            console.log("Error while fetching studios", err);
                        });
                    } else {
                        getAllStudios().then((response) => {
                            studios.value = response.data;

                            studios.value.forEach((studio) => {
                                const marker = new google.maps.Marker({
                                    position: {
                                        lat: parseFloat(studio.lat),
                                        lng: parseFloat(studio.lng),
                                    },
                                    map: map,
                                    title: studio.City + ', ' + studio.state.Code,
                                });

                                const infoWindow = new google.maps.InfoWindow({
                                    content: `<div class="find-map-info-window">
                                    <h5>${studio.City}, ${studio.state.Code}</h5>
                                    <p>${studio.Line1}</p>
                                    <p>${studio.City}, ${studio.state.Code} ${studio.ZipCode}</p>
                                    <p>${studio.phone}</p>
                                </div>`,
                                });

                                marker.addListener('click', () => {
                                    infoWindow.open(map, marker);
                                });
                            });
                        }).catch((err) => {
                            console.log("Error while fetching all studios", err);
                        });
                    }
                });
            } else {
                console.error("Map element not found");
            }
        });
    }).catch((error) => {
        console.log("Failed to load Google Maps", error);
    });
});

</script>


<template>
    <SectionContainer widthSize="40" class="find-location-left">
        <ElementContaier class="find-location-title">
            <Text type="H3" text="Find your studio" />
        </ElementContaier>
        <ElementContaier>
            <div class="find-location-form">
                <Form @submit="onSubmit">
                    <div class="find-location-field">
                        <label for="autocomplete" class="sr-only">Enter your State, City, Postal Code, or Zip
                            Code</label>
                        <div class="find-location-field-icon">
                            <SearchIcon />
                        </div>
                        <div>
                            <input type="text" v-model="searchQuery"
                                placeholder="Search location" id="autocomplete" class="form-control" />
                            <!-- <ul v-if="autocompleteResults.length > 0" class="autocomplete-results">
                                <li v-for="(result, index) in autocompleteResults" :key="index"
                                    @click="selectPlace(result)">
                                    {{ result.description }}
                                </li>
                            </ul> -->
                        </div>
                        <!-- Display the error message if searchQuery is empty and user clicks submit -->
                        <div v-if="hasError" class="invalid-feedback">{{ searchError }}</div>
                        <Button type="submit" text="Search" class="btn-fl-search-mobile" />
                    </div>
                </Form>
            </div>
        </ElementContaier>
        <template v-if="isMobile">
            <ElementContaier>
                <div class="find-google-map">
                    <div class="find-google-map-main">
                        <template v-if="studios.length > 0">
                            <GoogleMap :api-key="GOOGLE_API_KEY" style="width: 100%; height: 640px"
                                :center="mapLocation" :zoom="zoom">
                                <Marker v-for="(studio) in studios" :key="studio.ID" :options="{
                    position: {
                        lat: parseFloat(studio?.lat),
                        lng: parseFloat(studio?.lng),
                    }
                }">
                                    <InfoWindow>
                                        <div class="find-map-info-window">
                                            <Text @click="selectStudioButton(studio.url_slug)"
                                                class="find-map-info-window-title cursor-pointer" type="H5"
                                                :text="studio.City + ', ' + studio?.state?.Code" />
                                            <Text class="find-map-info-window-paragraph" type="P"
                                                :text="studio.Line1" />
                                            <Text class="find-map-info-window-paragraph" type="P"
                                                :text="studio.City + ', ' + studio?.state?.Code + ' ' + studio?.ZipCode" />
                                            <Text class="find-map-info-window-paragraph" type="P"
                                                :text="studio.phone" />
                                        </div>
                                    </InfoWindow>
                                </Marker>
                            </GoogleMap>
                        </template>
                        <template v-else>
                            <GoogleMap :api-key="GOOGLE_API_KEY" style="width: 100%; height: 640px"
                                :center="mapLocation" :zoom="zoom">
                            </GoogleMap>
                        </template>
                    </div>
                </div>
            </ElementContaier>
        </template>
        <div class="find-location-address" v-for="(studio) in studios" :key="studio.ID">
            <ElementContaier class="find-location-address-content-main">
                <template v-if="!isMobile">
                    <div class="find-location-address-icon">
                        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.1824 17.2895L19.4196 9.14211C20.4493 8.12368 21 6.77368 21 5.35263C21 3.93158 20.4253 2.58158 19.4196 1.56316C18.39 0.544737 17.0251 7.94075e-08 15.5884 7.94075e-08C14.1277 7.94075e-08 12.7868 0.568421 11.7571 1.56316L10.512 2.79474L9.26682 1.56316C7.15964 -0.521053 3.71152 -0.521053 1.58039 1.56316C-0.526796 3.64737 -0.526796 7.0579 1.58039 9.16579L10.512 18L11.1824 17.2895Z"
                                fill="#EF3147" />
                        </svg>
                    </div>
                </template>
                <div class="find-location-address-content">
                    <Text type="H4" :text="studio.City + ', ' + studio.state.Code" />
                    <Text :text="studio.Line1" />
                    <Text :text="studio.City + ', ' + studio.state.Code + ' ' + studio.ZipCode" />
                    <Text :text="studio.phone" />
                </div>
            </ElementContaier>
            <ElementContaier class="find-location-address-buttons">
                <!-- <router-link :to="{ path:'/' + studio.url_slug, query: queryParams }" class="select-studio-link">
                    <Button :text="isMobile ? 'Select' : 'Select Studio'"/>
                </router-link> -->
                <Button :text="isMobile ? 'Select' : 'Select Studio'" @click="selectStudioButton(studio.url_slug)" />
                <router-link :to="{ path: '/' + studio.url_slug + '/signup', query: queryParams }"
                    class="get-trial-offer-link">
                    <Text text="Get intro offer" />
                </router-link>
            </ElementContaier>
        </div>
        <ElementContaier class="find-location-text-info">
            <Text text="Don’t see what you’re looking for?" class="find-location-sub-title" />
            <Text type="HTML" :text=findLocationText />
        </ElementContaier>
    </SectionContainer>
    <template v-if="!isMobile">
        <SectionContainer widthSize="60" class="find-location-right">
            <ElementContaier>
                <div class="find-google-map">
                    <div class="find-google-map-main">
                        <template v-if="studios.length > 0">
                            <GoogleMap :api-key="GOOGLE_API_KEY" style="width: 100%; height: 640px"
                                :center="mapLocation" :zoom="zoom">
                                <Marker v-for="(studio) in studios" :key="studio.ID" :options="{
                    position: {
                        lat: parseFloat(studio?.lat),
                        lng: parseFloat(studio?.lng),
                    }
                }">
                                    <InfoWindow>
                                        <div class="find-map-info-window">
                                            <Text @click="selectStudioButton(studio.url_slug)"
                                                class="find-map-info-window-title cursor-pointer" type="H5"
                                                :text="studio.City + ', ' + studio?.state?.Code" />
                                            <Text class="find-map-info-window-paragraph" type="P"
                                                :text="studio.Line1" />
                                            <Text class="find-map-info-window-paragraph" type="P"
                                                :text="studio.City + ', ' + studio?.state?.Code + ' ' + studio?.ZipCode" />
                                            <Text class="find-map-info-window-paragraph" type="P"
                                                :text="studio.phone" />
                                        </div>
                                    </InfoWindow>
                                </Marker>
                            </GoogleMap>
                        </template>
                        <template v-else>
                            <GoogleMap :api-key="GOOGLE_API_KEY" style="width: 100%; height: 640px"
                                :center="mapLocation" :zoom="zoom">
                            </GoogleMap>
                        </template>
                    </div>
                </div>
            </ElementContaier>
        </SectionContainer>
    </template>
</template>