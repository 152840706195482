import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home';
import Studio from '../views/Studio';
import StudioSignup from '../views/StudioSignup';
import FindLocation from '../views/FindLocation';
import DayOne from '../views/DayOne';
import Workout from '../views/Workout';
import Faqs from '../views/Faqs';
import About from '../views/About';
import TermsOfUse from '../views/TermsOfUse';
import PrivacyPolicy from '../views/PrivacyPolicy';
import CoronaVirus from '../views/CoronaVirus';
import Cookie_Policy from '../views/Cookie_Policy';
import Contact from '../views/Contact';
import ModulePreview from '../views/ModulePreview';
import AccessibilityStatement from "../views/AccessibilityStatement.vue"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: {
      pageName: 'HOME_PAGE',
      pageKey: 'main',
      isPreview: false
    }

  },
  {
    path: '/find-location',
    name: 'FindLocation',
    component: FindLocation,
    props: {
      pageName: 'FIND_LOCATION_PAGE',
      pageKey: 'find-location',
      isPreview: false
    }
  },
  {
    path: '/day-one',
    name: 'DayOne',
    component: DayOne,
    props: {
      pageName: 'DAY_ONE_PAGE',
      pageKey: 'day-one',
      isPreview: false
    }
  },
  {
    path: '/action-packed-workout',
    name: 'Workout',
    component: Workout,
    props: {
      pageName: 'WORKOUT_PAGE',
      pageKey: 'action-packed-workout',
      isPreview: false
    }
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: Faqs,
    props: {
      pageName: 'FAQS_PAGE',
      pageKey: 'faqs',
      isPreview: false
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    props: {
      pageName: 'ABOUT_PAGE',
      pageKey: 'about',
      isPreview: false
    }
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: TermsOfUse,
    props: {
      pageName: 'TERMS_PAGE',
      pageKey: 'terms-of-use',
      isPreview: false
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    props: {
      pageName: 'PRIVACY_POLICY_PAGE',
      pageKey: 'privacy-policy',
      isPreview: false
    }
  },
  {
    path: '/accessibility-statement',
    name: 'AccessibilityStatement',
    component: AccessibilityStatement,
    props: {
      pageName: 'ACCESSIBILITY_STATEMENT_PAGE',
      pageKey: 'accessibility-statement',
      isPreview: false
    }
  },
  {
    path: '/coronavirus',
    name: 'CoronaVirus',
    component: CoronaVirus,
    props: {
      pageName: 'COVID_PAGE',
      pageKey: 'main',
      isPreview: false
    }
  },
  {
    path: '/cookie-policy',
    name: 'Cookie_Policy',
    component: Cookie_Policy,
    props: {
      pageName: 'Cookie_Policy',
      pageKey: 'main',
      isPreview: false
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    props: {
      pageName: 'CONTACT_PAGE',
      pageKey: 'contact',
      isPreview: false
    }
  },
  {
    path: '/preview/:moduleId',
    name: 'Preview',
    component: ModulePreview,
    props: {
      pageName: 'CONTACT_PAGE',
      pageKey: 'main',
      isPreview: true
    }
  },
  {
    path: '/:studio',
    name: 'Studio',
    component: Studio,
    params: true,
    props: {
      pageName: 'STUDIO_PAGE',
      pageKey: 'studio',
      isPreview: false
    }
  },
  {
    path: '/:studio/signup',
    name: 'Signup',
    component: StudioSignup,
    params: true,
    props: {
      pageName: 'STUDIO_SIGNUP_PAGE',
      pageKey: 'signup',
      isPreview: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else {
      return {
        top: 0,
        behavior: 'smooth'
      }
    }
  }
})

export default router
