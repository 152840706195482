<script setup>
import Header from '../components/layout/Header.vue';
import Footer from '../components/layout/Footer.vue';
import { ref,onMounted, nextTick } from "vue";

const CookiesSScriptFunction = (d, s, id) => {
  var js, tjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = "https://app.termly.io/embed-policy.min.js";
  js.async = true;
  tjs.parentNode.insertBefore(js, tjs);
};
const showFooter = ref(false);
onMounted(() => {
  CookiesSScriptFunction(document, 'script', 'termly-jssdk');
  nextTick(() => {
    // const termlyEmbed = document.querySelector('[name="termly-embed"]');
    // if (termlyEmbed) {
    //   showFooter.value = true;
    // }
    setTimeout(() => {
     
    const element = document.getElementById("c783659a-8a64-4eaa-ae7a-f8a43d950a4f");
    if (element) {
      element.style.backgroundColor = "white";
      showFooter.value = true;
    }
   
  }, 200); // Delay to ensure the element is available
  });
});
</script>

<template>
  <div class="page-container">
    <Header :currentStudio="currentStudio" :queryParams="queryParams" />
    <h3></h3>
    <div class="main-content">
      <div name="termly-embed" id="divdel" 
           data-id="c783659a-8a64-4eaa-ae7a-f8a43d950a4f" 
           
          >
      </div>
    </div>

    <Footer v-if="showFooter" :currentStudio="currentStudio" :queryParams="queryParams" />
  </div>
</template>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

.main-content {
  margin-top: 20px;
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
