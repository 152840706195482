<!-- LeadCaptureModal.vue -->
<script>
import { ref, defineProps, onMounted, computed } from "vue";
import { customerInfoLead } from "../../mixins/api";
import { useToast } from "vue-toastification";

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    currentStudio: {
      type: Object,
    },
    PosSystemUrl: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const form = ref({
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      email_subscription: true,
      sms_subscription: true,
      submitted: false,
    });

    const studioId = computed(() => {
      return props.currentStudio?.ID ? props.currentStudio?.ID : 0;
    });

    const toast = useToast(); // Initialize useToast and assign it to toast variable

    const submitForm = async (PostURL) => {
      form.value.submitted = true;
      const formData = {
        ...form.value,
        email_subscription: form.value.email_subscription ? 1 : 0,
        sms_subscription: form.value.sms_subscription ? 1 : 0,
        studioId: props.currentStudio?.ID || 0,
      };
      // console.log(formData); // Log the formData object
      try {
        const { firstName, lastName, telephone, email } = form.value;

        // Check for empty fields
        const emptyFields = [];
        if (!firstName) emptyFields.push("First Name");
        if (!lastName) emptyFields.push("Last Name");
        if (!telephone) emptyFields.push("Telephone");
        if (!email) emptyFields.push("Email");

        if (emptyFields.length === 0) {
          var customer = {
            first_name: form.value.firstName,
            last_name: form.value.lastName,
            telephone: form.value.telephone,
            email: form.value.email,
            email_subscription: form.value.email_subscription ? true : false,
            sms_subscription: form.value.sms_subscription ? true : false,
          };
          customerInfoLead(customer, props.currentStudio?.ID || 0).then(
            (response) => {
              window.location.href = PostURL;
            }
          );
          if (typeof gtag === "function") {
            gtag("event", "conversion", {
              send_to: "AW-16596255233/ExvtCPWWoroZEIGE2-k9",
            });
            //  alert('gtag is working');
          } else {
            // alert('gtag is not defined');
            //console.error('gtag is not defined');
          }
        } else {
          toast.error(`*Required: ${emptyFields.join(", ")}`);
          form.value.submitted = false;
          return;
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };

    return {
      form,
      studioId,
      submitForm,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Darkens the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  color: black;
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  max-width: 95%;
  width: 65%;
  margin: 0 auto;
}

/* Media query for mobile screens */
@media (max-width: 767px) {
  .row {
    flex-direction: column;
  }

  .form-group.half-width {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .modal-container {
    width: 90%; /* Width for mobile screens */
    margin: 25vh auto 0 auto; /* 25% margin from the top */
    transform: translateY(-25%);
  }
  .button-container p {
    font-size: 14px; /* Adjust this value to make the text smaller */
    text-align: center; /* Center the text */
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.disclaimer {
  font-size: 0.6em;
  color: #666;
  margin-top: 5px;
  text-align: justify;
  word-wrap: break-word;
}

.row {
  display: flex;
  justify-content: space-between;
}

.form-group.half-width {
  flex: 1;
}

.row .form-group.half-width:first-child {
  padding-left: 0;
}

.row .form-group.half-width:last-child {
  padding-right: 0;
}

.half-width label {
  font-size: 14px;
  color: gray;
  margin-left: 5px;
}

.p-1 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.form-group.form-check .form-check-label {
  color: black;
}
</style>

<template>
  <div v-if="show" class="modal-overlay" @click="close">
    <div class="modal-container" @click.stop>
      <button class="modal-close" @click="close">X</button>
      <div class="button-container">
        <p><strong>We’d love to know a little bit more about you.</strong></p>
      </div>
      <form @submit.prevent="submitForm">
        <div class="p-1">
          <div class="row">
            <div class="form-group half-width">
              <label>First Name</label>
              <input
                class="form-control"
                type="text"
                id="firstName"
                v-model="form.firstName"
                required
              />
            </div>
            <div class="form-group half-width">
              <label>Last Name</label>
              <input
                class="form-control"
                type="text"
                id="lastName"
                v-model="form.lastName"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group half-width">
              <label for="telephone">Telephone</label>
              <input
                type="tel"
                id="telephone"
                class="form-control"
                v-model="form.telephone"
                required
              />
            </div>
            <div class="form-group half-width">
              <label for="email">Email</label>
              <input
                type="email"
                id="email"
                class="form-control"
                v-model="form.email"
                required
              />
            </div>
          </div>
        </div>
        <!--
        <div class="form-group form-check">
          <input type="checkbox" class="form-check-input" :value="true" v-model="form.email_subscription" />
          <label class="form-check-label text-dark">
            I agree to receive emails about special offers and news from iLoveKickboxing.
          </label>
        </div>
        <div class="form-group form-check">
          <input type="checkbox" class="form-check-input" checked="true" v-model="form.sms_subscription" />
          <label class="form-check-label text-dark">
            I agree to receive promotional text messages about special offers and news from iLoveKickboxing. I can
            opt-out at any time by replying STOP. Standard message rates may apply.
          </label>
        </div> 
        -->
        <div class="button-container">
          <button
            type="button"
            :disabled="form.submitted"
            @click="submitForm(PosSystemUrl)"
            v-bind:class="{
              'btn btn-sm btn-danger': form.submitted,
              'btn btn-sm btn-primary': !form.submitted,
            }"
          >
            {{ form.submitted ? "submitted" : "LET'S DO THIS!" }}
          </button>
        </div>
        <div class="p-15">
          <p class="disclaimer" style="font-weight: bold;">
            By submitting this form, I agree to receive emails, text messages, and other communications, including informational and promotional messages, from I Love Kickboxing. Message frequency varies. I can opt out at any time by replying STOP or get help by replying HELP. Standard message rates may apply. For further information please see our <a href="https://www.ilovekickboxing.com/privacy-policy" target="_blank" rel="noopener noreferrer" class="text-decoration-none">Privacy Policy</a> and our <a href="https://www.ilovekickboxing.com/terms-of-use" target="_blank" rel="noopener noreferrer" class="text-decoration-none">Terms of Service</a>.
          </p>
        </div>
      </form>
    </div>
  </div>
</template>
