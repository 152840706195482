<script setup>
import { onBeforeMount, onUnmounted, ref, onMounted, defineProps } from "vue";
import LocationIcon from "../../assets/svg/LocationIcon.vue";
//import CalendarIcon from '../../assets/svg/CalendarIcon.vue';
import ProfileIcon from '../../assets/svg/ProfileIcon.vue';
import MenuIcon from '../../assets/svg/MenuIcon.vue';
import MenuCloseIcon from "../../assets/svg/MenuCloseIcon.vue";
import GetApp from '../modules/GetApp.vue';
// import Location from "../elements/Location.vue";
import Image from "../elements/Image.vue";
import Button from "../elements/Button.vue";
import Navbar from "../elements/Navbar.vue";
import GetStarted from "../elements/GetStarted.vue";
import Modal from "../elements/Modal.vue";
import CloseIcon from "../../assets/svg/CloseIcon.vue";
import { triggerTrackEvents } from "../../../src/mixins/events_handler";
import { useRoute, useRouter } from "vue-router";

const props = defineProps({
  currentStudio: {
    type: Object
  },
  queryParams: {
    type: Object
  }
});

const router = useRouter()
const route = useRoute()
const studio = ref([]);
const isSticky = ref(false);
const stickyClass = ref("is_sticky");
const scrollPosition = ref(0);
const isMobile = ref(false);
const isMenuExpanded = ref(false);
const getMobileMenu = () => {
  isMenuExpanded.value = !isMenuExpanded.value;
};

const handleScroll = () => {
  scrollPosition.value = window.scrollY;
  if (scrollPosition.value >= 10) {
    isSticky.value = true;
  } else {
    isSticky.value = false;
  }
};

const checkIsMobile = () => {
  let width = window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  isMobile.value = width < 640 ? true : false;
};
const redirectedToCheckout = ref(true);
const isModalOption = ref(false);
const showModalOption = () => {
  triggerTrackEvents('Header', 'Get Intro Offer', 'head_get_offers');
  redirectedToCheckout.value = true;
  isModalOption.value = true;
};
const showLearnMoreModalOption = () => {
  triggerTrackEvents('Header', 'Get Intro Offer', 'head_get_offers');
  redirectedToCheckout.value = false;
  isModalOption.value = true;
}
const clickChooseYourStudio = () => {
  triggerTrackEvents('Header', 'Choose Your Studio', 'head_search_locations')
  router.push({ path: '/find-location', query: props?.queryParams })
}
const closeModalOption = () => {
  isModalOption.value = false;
  redirectedToCheckout.value = true;
};

const redirectToLocation = () => {
  router
    .push({ path: '/' + studio.value.slug, query: props?.queryParams })
    .then(() => { router.go(0) })
}

onBeforeMount(() => {
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", checkIsMobile);
});
onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
onMounted(() => {
  checkIsMobile();
  const cookieCurrentStudio = sessionStorage.getItem('cookieCurrentStudio')
  setTimeout(function () {
    if (cookieCurrentStudio) {
      studio.value = JSON.parse(cookieCurrentStudio);
    }
  }, 1000);
});


</script>
<template>
  <template v-if="isMobile">
  </template>
  <!-- <template v-else>
    <div class="header_top_wrp">
      <div class="container-fluid">
        <div class="row">
          <div class="col-50">
            <div class="notetexttop">
              <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                role="presentation">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.9308 0.881028C9.54028 0.490504 8.90711 0.490504 8.51659 0.881028C8.12606 1.27155 8.12606 1.90472 8.51659 2.29524L12.6797 6.45831H1.00586C0.453575 6.45831 0.00585938 6.90603 0.00585938 7.45831C0.00585938 8.0106 0.453575 8.45831 1.00586 8.45831H12.6793L8.51659 12.621C8.12606 13.0116 8.12606 13.6447 8.51659 14.0353C8.90711 14.4258 9.54028 14.4258 9.9308 14.0353L15.7851 8.18093C15.9754 7.99888 16.0939 7.74243 16.0939 7.45831C16.0939 7.2864 16.0505 7.12463 15.9741 6.98333C15.9352 6.91123 15.8867 6.84291 15.8288 6.7802C15.8196 6.77022 15.8102 6.76043 15.8006 6.75084L9.9308 0.881028Z"
                  fill="#EF3147" />
              </svg>
              <span>
                New clients: Get up to 70% off with our special Day One offers.
                                         <router-link :to="{ path: '/action-packed-workout', query:  props?.queryParams}" class="nav-link">Learn more</router-link>
                <a href="javascript:void();" class="nav-link" @click="showLearnMoreModalOption" role="button">Learn
                  more</a>
              </span>
            </div>
          </div>

          <div class="col-50">
            <div class="header_top_right">
              <ul class="nav">
                <template v-if="!currentStudio?.ID && studio?.slug">
                  <li class="nav-item">
                    <a href="javascript:void();" class="nav-link" @click="redirectToLocation">
                      <span class="nav-icon">
                        <LocationIcon />
                      </span>
                      Your studio {{ studio.name }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <span type="button" class="nav-link" @click="clickChooseYourStudio">
                      Not your studio?
                    </span>
                  </li>
                </template>
                <li class="nav-item" v-else>
                  <span type="button" class="nav-link" @click="clickChooseYourStudio">
                    <span class="nav-icon">
                      <LocationIcon />
                    </span>
                    Choose your studio
                  </span>
                </li>
                <li class="nav-item"
                  v-if="$route.name == 'Studio' && currentStudio && currentStudio?.mindbody_site_id != null">
                  <a :href="`https://clients.mindbodyonline.com/ASP/su1.asp?studioid=` + (currentStudio?.mindbody_site_id)"
                    class="nav-link" target="_blank">
                    <span class="nav-icon">
                      <ProfileIcon />
                    </span>
                    Login
                  </a>
                </li>
                <li class="nav-item">
                                <router-link :to="{ path: '/', query:  props?.queryParams}" class="nav-link">
                                    <span class="nav-icon"><CalendarIcon /></span>
                                    Book a class
                                </router-link>
                            </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template> -->
  <header class="header_wrp" :class="[isSticky ? stickyClass : '', isMenuExpanded ? 'header-menu-expanded' : '']">
    <nav class="navbar">
      <div class="container-fluid">
        <div class="header_main_wrp">
          <router-link :to="{ path: '/', query: props?.queryParams }" class="navbar-brand">
            <template v-if="isMobile">
              <template v-if="isMenuExpanded">
                <svg width="138" height="36" viewBox="0 0 138 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_401_1643)">
                    <path d="M0 16.401V0H3.95238V16.401H0Z" fill="#0A072E" />
                    <path d="M10.8477 16.401V0H14.8V12.9436H21.1152V16.401H10.8477Z" fill="#0A072E" />
                    <path
                      d="M46.4834 16.401H42.5525L36.3662 0H40.7912L44.5717 11.6255H44.6576L48.4167 0H52.7772L46.4834 16.401Z"
                      fill="#0A072E" />
                    <path
                      d="M53.916 16.401V0H64.871V3.34934H57.718V6.43938H64.4843V9.61585H57.718V13.03H65.3006V16.401H53.916Z"
                      fill="#0A072E" />
                    <path
                      d="M9.96687 35.5461L3.90942 28.0047H3.86646V35.5461H0V19.1667H3.86646V25.8438H3.9309L9.75206 19.1667H14.6711L7.64699 26.7082L15.0792 35.5461H9.96687Z"
                      fill="#0A072E" />
                    <path d="M16.0029 35.5461V19.1667H19.9553V35.5677H16.0029V35.5461Z" fill="#0A072E" />
                    <path
                      d="M30.1155 36.0001C25.0891 36.0001 21.3516 32.4778 21.3516 27.335C21.3516 22.0841 25.218 18.7131 30.2014 18.7131C32.4784 18.7131 34.9486 19.5559 36.3018 21.1117L33.6383 23.7912C32.908 22.7756 31.5977 22.2786 30.3518 22.2786C27.6023 22.2786 25.6476 24.4178 25.6476 27.3134C25.6476 30.2954 27.5594 32.3482 30.2659 32.3482C31.8125 32.3482 33.0368 31.6567 33.7242 30.6843L36.4737 33.2774C35.013 34.9412 32.865 36.0001 30.1155 36.0001Z"
                      fill="#0A072E" />
                    <path
                      d="M47.6436 35.5461L41.5862 28.0047H41.5432V35.5461H37.6768V19.1667H41.5432V25.8438H41.6077L47.4288 19.1667H52.3478L45.3237 26.7082L52.7559 35.5461H47.6436Z"
                      fill="#0A072E" />
                    <path
                      d="M60.2527 35.5461H53.7656V19.1667H60.2527C62.7874 19.1667 65.7946 20.0743 65.7946 23.3804C65.7946 25.1956 64.7206 26.384 63.131 26.9243V26.9675C64.9998 27.2916 66.4605 28.6313 66.4605 30.7922C66.4605 34.3576 63.3458 35.5461 60.2527 35.5461ZM59.4794 22.3H57.5462V25.671H59.6727C61.1549 25.671 61.9926 25.0443 61.9926 23.8774C61.9926 22.797 61.1334 22.3 59.4794 22.3ZM59.7372 28.6097H57.5462V32.3696H59.7586C61.0045 32.3696 62.5296 32.0239 62.5296 30.4249C62.5296 29.0419 61.4126 28.6097 59.7372 28.6097Z"
                      fill="#0A072E" />
                    <path
                      d="M76.4485 36.0001C71.3577 36.0001 67.5771 32.4778 67.5771 27.2918C67.5771 22.0409 71.3577 18.7131 76.4485 18.7131C81.5608 18.7131 85.3414 22.0193 85.3414 27.2918C85.3414 32.4778 81.5608 36.0001 76.4485 36.0001ZM76.4485 22.2786C73.6776 22.2786 71.8088 24.4178 71.8088 27.2702C71.8088 30.2305 73.7205 32.3698 76.4485 32.3698C79.198 32.3698 81.1312 30.2305 81.1312 27.2702C81.1312 24.4178 79.2195 22.2786 76.4485 22.2786Z"
                      fill="#0A072E" />
                    <path
                      d="M95.2224 35.5461L91.6567 29.755L88.2198 35.5461H83.5156L89.2294 26.881L83.9882 19.1667H88.7353L91.7641 24.2664L94.7498 19.1667H99.3251L94.1699 26.9026L100.055 35.5677H95.2224V35.5461Z"
                      fill="#0A072E" />
                    <path d="M100.936 35.5461V19.1667H104.888V35.5677H100.936V35.5461Z" fill="#0A072E" />
                    <path
                      d="M116.617 35.5461L110.066 24.8282H109.98L110.066 35.5461H106.242V19.1667H110.732L117.262 29.863H117.326L117.24 19.1667H121.064V35.5677H116.617V35.5461Z"
                      fill="#0A072E" />
                    <path
                      d="M131.266 35.9784C126.068 35.9784 122.309 32.4995 122.309 27.335C122.309 22.0841 126.175 18.7131 131.158 18.7131C133.715 18.7131 136.077 19.5775 137.452 20.8956L134.96 23.748C134.209 22.862 132.898 22.2137 131.309 22.2137C128.538 22.2137 126.519 24.3962 126.519 27.335C126.519 30.3386 128.302 32.4995 131.481 32.4995C132.404 32.4995 133.264 32.3698 133.951 32.0673V29.0853H130.815V25.8872H137.538V34.5739C135.949 35.395 133.779 35.9784 131.266 35.9784Z"
                      fill="#0A072E" />
                    <path
                      d="M27.3448 15.796L34.734 8.36267C35.6577 7.43349 36.1517 6.2018 36.1517 4.90528C36.1517 3.60876 35.6362 2.37707 34.734 1.4479C33.8104 0.518727 32.586 0.0217286 31.2972 0.0217286C29.9869 0.0217286 28.784 0.540336 27.8603 1.4479L26.7433 2.57155L25.6264 1.4479C23.7361 -0.453662 20.6429 -0.453662 18.7312 1.4479C16.8409 3.34946 16.8409 6.46111 18.7312 8.38427L26.7433 16.4443L27.3448 15.796Z"
                      fill="#EF3147" />
                  </g>
                  <defs>
                    <clipPath id="clip0_401_1643">
                      <rect width="137.538" height="36" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </template>
              <template v-else>
                <svg width="138" height="36" viewBox="0 0 138 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_401_1938)">
                    <path d="M0 16.401V0H3.95238V16.401H0Z" fill="white" />
                    <path d="M10.8477 16.401V0H14.8V12.9436H21.1152V16.401H10.8477Z" fill="white" />
                    <path
                      d="M46.4834 16.401H42.5525L36.3662 0H40.7912L44.5717 11.6255H44.6576L48.4167 0H52.7772L46.4834 16.401Z"
                      fill="white" />
                    <path
                      d="M53.916 16.401V0H64.871V3.34934H57.718V6.43938H64.4843V9.61585H57.718V13.03H65.3006V16.401H53.916Z"
                      fill="white" />
                    <path
                      d="M9.96687 35.5461L3.90942 28.0047H3.86646V35.5461H0V19.1667H3.86646V25.8438H3.9309L9.75206 19.1667H14.6711L7.64699 26.7082L15.0792 35.5461H9.96687Z"
                      fill="white" />
                    <path d="M16.0029 35.5461V19.1667H19.9553V35.5677H16.0029V35.5461Z" fill="white" />
                    <path
                      d="M30.1155 36.0001C25.0891 36.0001 21.3516 32.4778 21.3516 27.335C21.3516 22.0841 25.218 18.7131 30.2014 18.7131C32.4784 18.7131 34.9486 19.5559 36.3018 21.1117L33.6383 23.7912C32.908 22.7756 31.5977 22.2786 30.3518 22.2786C27.6023 22.2786 25.6476 24.4178 25.6476 27.3134C25.6476 30.2954 27.5594 32.3482 30.2659 32.3482C31.8125 32.3482 33.0368 31.6567 33.7242 30.6843L36.4737 33.2774C35.013 34.9412 32.865 36.0001 30.1155 36.0001Z"
                      fill="white" />
                    <path
                      d="M47.6436 35.5461L41.5862 28.0047H41.5432V35.5461H37.6768V19.1667H41.5432V25.8438H41.6077L47.4288 19.1667H52.3478L45.3237 26.7082L52.7559 35.5461H47.6436Z"
                      fill="white" />
                    <path
                      d="M60.2527 35.5461H53.7656V19.1667H60.2527C62.7874 19.1667 65.7946 20.0743 65.7946 23.3804C65.7946 25.1956 64.7206 26.384 63.131 26.9243V26.9675C64.9998 27.2916 66.4605 28.6313 66.4605 30.7922C66.4605 34.3576 63.3458 35.5461 60.2527 35.5461ZM59.4794 22.3H57.5462V25.671H59.6727C61.1549 25.671 61.9926 25.0443 61.9926 23.8774C61.9926 22.797 61.1334 22.3 59.4794 22.3ZM59.7372 28.6097H57.5462V32.3696H59.7586C61.0045 32.3696 62.5296 32.0239 62.5296 30.4249C62.5296 29.0419 61.4126 28.6097 59.7372 28.6097Z"
                      fill="white" />
                    <path
                      d="M76.4485 36.0001C71.3577 36.0001 67.5771 32.4778 67.5771 27.2918C67.5771 22.0409 71.3577 18.7131 76.4485 18.7131C81.5608 18.7131 85.3414 22.0193 85.3414 27.2918C85.3414 32.4778 81.5608 36.0001 76.4485 36.0001ZM76.4485 22.2786C73.6776 22.2786 71.8088 24.4178 71.8088 27.2702C71.8088 30.2305 73.7205 32.3698 76.4485 32.3698C79.198 32.3698 81.1312 30.2305 81.1312 27.2702C81.1312 24.4178 79.2195 22.2786 76.4485 22.2786Z"
                      fill="white" />
                    <path
                      d="M95.2224 35.5461L91.6567 29.755L88.2198 35.5461H83.5156L89.2294 26.881L83.9882 19.1667H88.7353L91.7641 24.2664L94.7498 19.1667H99.3251L94.1699 26.9026L100.055 35.5677H95.2224V35.5461Z"
                      fill="white" />
                    <path d="M100.936 35.5461V19.1667H104.888V35.5677H100.936V35.5461Z" fill="white" />
                    <path
                      d="M116.617 35.5461L110.066 24.8282H109.98L110.066 35.5461H106.242V19.1667H110.732L117.262 29.863H117.326L117.24 19.1667H121.064V35.5677H116.617V35.5461Z"
                      fill="white" />
                    <path
                      d="M131.266 35.9784C126.068 35.9784 122.309 32.4995 122.309 27.335C122.309 22.0841 126.175 18.7131 131.158 18.7131C133.715 18.7131 136.077 19.5775 137.452 20.8956L134.96 23.748C134.209 22.862 132.898 22.2137 131.309 22.2137C128.538 22.2137 126.519 24.3962 126.519 27.335C126.519 30.3386 128.302 32.4995 131.481 32.4995C132.404 32.4995 133.264 32.3698 133.951 32.0673V29.0853H130.815V25.8872H137.538V34.5739C135.949 35.395 133.779 35.9784 131.266 35.9784Z"
                      fill="white" />
                    <path
                      d="M27.3448 15.796L34.734 8.36267C35.6577 7.43349 36.1517 6.2018 36.1517 4.90528C36.1517 3.60876 35.6362 2.37707 34.734 1.4479C33.8104 0.518727 32.586 0.0217286 31.2972 0.0217286C29.9869 0.0217286 28.784 0.540336 27.8603 1.4479L26.7433 2.57155L25.6264 1.4479C23.7361 -0.453662 20.6429 -0.453662 18.7312 1.4479C16.8409 3.34946 16.8409 6.46111 18.7312 8.38427L26.7433 16.4443L27.3448 15.796Z"
                      fill="#EF3147" />
                  </g>
                  <defs>
                    <clipPath id="clip0_401_1938">
                      <rect width="137.538" height="36" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </template>
            </template>
            <template v-else>
              <Image alt="iLoveKickboxing Logo"
                src="https://res.cloudinary.com/ilovekickboxing/image/upload/f_auto,fl_lossy,q_auto:eco/v1730999214/ilkb-logo-white_iu3iwh.png" />
            </template>
          </router-link>
          <template v-if="isMobile">
            <Button v-if="currentStudio?.url_slug !== 'saskatoon'" class="btn-getoffer" text="Get offer" @click="showModalOption" />
            <button class="navbar-toggler" type="button" @click="getMobileMenu">
              <template v-if="isMenuExpanded">
                <span class="nav-menu-close-icon">
                  <MenuCloseIcon />
                </span>
              </template>
              <template v-else>
                <span class="nav-menu-icon">
                  <MenuIcon />
                </span>
              </template>
            </button>
          </template>
          <template v-if="!isMobile">
            <Navbar :currentStudio="currentStudio" :queryParams="queryParams" />
          </template>
        </div>
        <template v-if="isMenuExpanded">
          <div class="mobile-menu-wrapper">
            <!-- <Location /> -->
            <Navbar :currentStudio="currentStudio" :queryParams="queryParams" />
            <div class="notetexttop notetexttop_mobile">
              <span>
                New clients: Get up to 70% off with our special Day One offers.
                <!--                <router-link :to="{ path: '/action-packed-workout', query:  props?.queryParams}" class="nav-link">Learn more</router-link>-->
                <a href="javascript:void();" class="nav-link" @click="showLearnMoreModalOption" role="button">Learn
                  more</a>
              </span>
            </div>
          </div>
        </template>
      </div>
    </nav>
  </header>
  <Modal modalLarge="true" v-if="isModalOption">
    <template #body>
      <span class="modal-close" @click="closeModalOption">
        <CloseIcon />
      </span>
      <GetStarted :redirectedToCheckout="redirectedToCheckout" :redirectedToStudio="false"
        :currentStudio="currentStudio" />
    </template>
  </Modal>
</template>